@import 'src/assets/css/mixins';
@import 'src/assets/css/variables';
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &:before,
  &:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}

.formGroup {
  display: block;
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    font-size: $font-fifteen;
    color: $primary-text-color;
    font-weight: 400;
    margin-bottom: 0;
    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid $secondary-text-color;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
      @include bradius(5px, 5px, 5px, 5px);
    }
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid $secondary-text-color;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.inviteFriend {
  text-align: center;
  img {
    max-width: 100px;
  }
  h3 {
    margin: 20px auto 25px;
    font-size: 25px;
    font-weight: 600;
    color: $primary-text-color;
    line-height: 35px;
  }
  p {
    line-height: 31px;
    font-size: 20px;
    color: $primary-text-color;
  }
}
.formWrapper {
  max-width: 420px;
  margin: auto;
  text-align: center;
  h6 {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid $bg-grey;
    line-height: 0.1em;
    margin: 20px 0;
    font-size: 20px;
    span {
      background: #fff;
      padding: 0 10px;
    }
  }
  .close {
    margin-top: 15px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: #00AEEF;
  }
}
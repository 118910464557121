@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.currencyWrapper {
  .userImageWrapper {
    width: 130px;
    height: 130px;
    @include bradius(50%, 50%, 50%, 50%);
    border: 4px solid $secondary-text-color;
    position: relative;
    margin: 15px auto;

    .userImage {
      width: 100%;
      height: 100%;
      display: inline-block;
      @include bradius(50%, 50%, 50%, 50%);
      object-fit: cover;
    }
    .superHostImg {
      width: 30px;
      height: 30px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  h3 {
    font-size: 30px;
    font-weight: 600;
    color: $primary-text-color;
    line-height: 30px;
    word-break: break-all;
  }
  p {
    font-size: 20px;
    font-weight: 600;
    color: $warning-text-color;
    line-height: 30px;
  }
  .accountBalance {
    font-size: 16px;
    margin-top: 50px;
    color: $primary-text-color;
  }
  .cardWrapper {
    @include bradius(10px, 10px, 10px, 10px);
    background-color: $bg-grey;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    h3 {
      line-height: 32px;
      font-size: 30px;
      font-weight: 600;
      color: $secondary-text-color;
    }
    .goldImage {
      width: 65px;
    }
    .silverImage {
      width: 40px;
    }
  }
}
.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 15px;
  padding: 48px 0 24px;
  .cancelBtn {
    width: auto;
    background-color: transparent;
    box-shadow: none;
    color: $secondary-text-color;
    &:hover, &:active, &:focus {
      color: $secondary-text-color;
    }
  }
  .saveBtn {
    max-width: 220px;
  }
}
.rechargeAccountWrapper {
  max-width: 755px;
  width: 100%;
  padding: 25px 50px;
  .rechargeCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
  }
}

@import "./src/assets/css/variables";
@import "./src/assets/css/mixins";
.inputFiled {
  position: relative;
  height: $input-height;
  width: 100%;
  margin: auto;
  color: $text-field-light-color;
  font-size: $primary-font-size;
  font-weight: normal;
  padding: 0.375rem 1rem;
  border-color: $bg-grey;
  background-color: $bg-grey;
  @include bradius(5px, 5px, 5px, 5px);
  &:active, &:focus {
    outline: none;
    box-shadow: none;
    color: $text-field-light-color;
    border-color: $bg-grey;
    background-color: $bg-grey;
  }
  &::placeholder {
    color: $text-field-light-color;
    font-weight: normal;
  }
}
.errorMessage {
  color: red;
  font-size: 14px;
  text-align: left;
  width: 100%;
  font-weight: 400;
  margin-top: 5px;
}
.iconPdRight {
  padding-right: 40px;
}
.iconStyling {
  position: absolute;
  top: 20px;
  right: 20px;
}

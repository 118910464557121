@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.chatHeader {
  box-shadow: 0 1px 3px #0000001C;
  @include bradius(8px, 8px, 8px, 8px);
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    font-size: 19px;
    font-weight: 600;
    color: $secondary-text-color;
    line-height: 20px;
  }
  .chatInfoImage {
    width: 40px;
    margin-left: 25px;
  }
}
@import "variables";
@import "mixins";
%signUpBtn {
  background-color: $primary-btn-bg-color;
  max-width: 18.125rem;
  width: 100%;
  color: $text-white;
  font-size: $font-fourteen;
  box-shadow: 0 3px 6px #00AEEF33;
  height: $btn-height;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include bradius;
}
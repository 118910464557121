@import "src/assets/css/variables";
.radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radioLabel {
      &:before {
        content: '';
        background: $text-white;
        border-radius: 100%;
        border: 1px solid darken($text-field-light-color, 0%);
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radioLabel {
        &:before {
          background-color: $secondary-text-color;
          box-shadow: inset 0 0 0 4px #fff;
          border-color: $secondary-text-color;
        }
      }
    }
    &:focus {
      + .radioLabel {
        &:before {
          outline: none;
          border-color: $secondary-text-color;
        }
      }
    }
    &:disabled {
      + .radioLabel {
        &:before {
          box-shadow: inset 0 0 0 4px $text-field-light-color;
          border-color: darken($text-field-light-color, 0%);
          background: darken($text-field-light-color, 0%);
        }
      }
    }
    + .radioLabel {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.textArea {
  width: 100%;
  min-height: 160px;
  padding: 10px;
  text-align: left;
  border: 0;
  resize: none;
  color: $text-field-light-color;
  @include bradius(5px,5px,5px,5px);
  &:active, &:focus {
    outline: none;
  }
  &::placeholder {
    color: $text-field-light-color;
    font-weight: 500;
  }
}

@import "src/assets/css/variables";
@import "src/assets/css/mixins";
.menuLandingWrapper {
  display: flex;
  .leftSide {
    flex: 0 0 230px;
    max-width: 230px;
    width: 100%;
  }
  .rightSide {
    flex: 0 0 calc(100% - 310px);
    max-width: calc(100% - 310px);
    width: 100%;
    margin-left: 80px;
    position: relative;
  }
}

@import 'src/assets/css/variables';
.customContainer {
  width: 100%;
  display: flex;
}
.leftSectionWrapper {
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00aeef;
  opacity: 0.8;
  position: relative;
  &:after {
    content: '';
    background-image: url('../../assets/images/triangle-background.png');
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.logoImage {
  max-width: 420px;
}
.descriptionLine {
  font-size: 23px;
  font-weight: 400;
  margin-top: 50px;
}
.rightSectionWrapper {
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
}
.loginArea {
  padding-inline: 110px;
  background-color: $text-white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  .error {
    color: red;
    text-align: left;
    font-size: 14px;
  }
  .accountHeading {
    font-size: $card-heading-size;
    color: $primary-text-color;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .profileTitle {
    font-size: $font-fifteen;
    color: $primary-text-color;
    font-weight: 500;
  }
  .formWrapper {
    margin: 30px 0;
  }
  .signUpMarginBLock {
    margin: 40px 0;
  }
  .registerMarginBLock {
    margin: 35px 0;
  }
  .signingUpText {
    line-height: 24px;
  }
}

.enterCodeWidth {
  max-width: 600px;
  width: 600px;
  padding-inline: 50px;
  padding-bottom: 30px;
}
.buttonWidth {
  max-width: 200px;
}

@import "../../../assets/css/mixins";
@import "../../../assets/css/variables";
li {
  .listingInner {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
  }
  .userImageWrapper {
    width: 55px;
    height: 55px;
    @include bradius(50%, 50%, 50%, 50%);
    position: relative;
    .userImage {
      width: 100%;
      height: 100%;
      display: inline-block;
      @include bradius(50%, 50%, 50%, 50%);
      border: 4px solid $secondary-text-color;
      object-fit: cover;
    }
    .superHostImg {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .offlineUserImage {
      border: 4px solid $text-field-light-color;
    }
  }
  .userDetails {
    display: flex;
    align-items: center;
    flex: 0 0 calc(100% - 75px);
    max-width: calc(100% - 75px);
    width: 100%;
    padding-right: 25px;
    .userInner {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      h5 {
        color: $primary-text-color;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        color: $text-field-light-color;
        margin: 0;
        line-height: 19px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .notifications {
      span{
        font-size: 12px;
        color: $text-white;
      }
      .badgeDanger {
        background-color: #de1414;
      }
    }
  }
}
.activeChat {
  background-color: $text-white;
  border-radius: 8px;
}
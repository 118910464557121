@import 'src/assets/css/variables';
@import 'src/assets/css/mixins';

.dateFormatWrapper {
  padding: 0 70px 60px;
  min-width: 498px;
  .dateFormatInner {
   flex: 0 0 65%;
  }
  .btnWidth {
    max-width: 220px;
  }
}

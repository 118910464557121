.custom-container {
  max-width: 100%;
  width: 100%;
  padding-inline: 65px;
  margin-inline: auto;
}
@media only screen and (min-width: 1600px) {
  .custom-container {
    max-width: 1440px;
    width: 100%;
    padding-inline: 85px;
    margin-inline: auto;
  }
}

@import 'src/assets/css/variables';

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 15px;
  //padding-top: 48px;
  position: absolute;
  top: 70%;
  width: 100%;
  .cancelBtn {
    width: auto;
    background-color: transparent;
    box-shadow: none;
    color: $secondary-text-color;
    &:hover, &:active, &:focus {
      color: $secondary-text-color;
    }
  }
  .saveBtn {
    max-width: 220px;
  }
}
.iconWidth {
  width: 11px;
}
.wrapperModal {
  padding-top: 12px;
}
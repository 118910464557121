@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.wrapper {
  //background-color: $bg-grey;
  min-height: 100vh;
  .body {
    min-height: calc(100vh - 163px);
    .searchbarWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .searchBox {
        flex: 0 0 490px;
        .searchField {
          @include bradius(30px, 30px, 30px, 30px);
          background-color: $text-white;
          height: 44px;
          line-height: 44px;
          max-width: 490px;
          width: 100%;
          padding-left: 20px;
        }
        img {
          width: 15px;
          top: 15px;
          object-fit: contain;
        }
      }
    }
    .nearbyItemWrapper {
      background-color: $text-white;
      padding: 25px 0 40px;
      .searchResultsTopic {
        font-size: 20px;
        font-weight: 600;
        color: $primary-text-color;
      }
      .loaderIcon {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translateY(-50%);
      }
    }
    .groupChat {
      position: fixed;
      bottom: 85px;
      right: 50px;
      z-index: 1;
      .chatIcon {
        width: 20px;
        height: 20px;
        background-color: #de1414;
        color: $text-white;
        @include bradius(50%, 50%, 50%, 50%);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
      }
      img {
        width: 70px;
        height: 70px;
        object-fit: contain;
      }
    }
  }
}
.modalWrapper {
  max-width: 1000px;
  width: 900px;
  padding-inline: 50px;
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid #B7B7B7;
    padding-bottom: 20px;
    margin-bottom: 20px;
    h5 {
      font-size: 19px;
      color: $primary-text-color;
    }
    .topicButton {
      padding: 5px 16px;
      background-color: $warning-text-color;
      color: $text-white;
      font-size: 18px;
      font-weight: 500;
      @include bradius(4px, 4px, 4px, 4px);
      display: inline-block;
      margin-left: 16px;
    }
  }
  .modalRightHeader {
    display: flex;
    align-items: center;
    column-gap: 25px;
    img {
      width: 17px;
    }
  }
  .hostWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 25px;
    margin-bottom: 25px;
    .hostInner {
      position: relative;
      width: 80px;
      height: 80px;
      border: 4px solid $secondary-text-color;
      @include bradius(50%, 50%, 50%, 50%);
      .hostImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include bradius(50%, 50%, 50%, 50%);
      }
      .superKeyImage {
        width: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .hostName {
      font-size: 24px;
      color: $primary-text-color;
      font-weight: 500;
      margin: 0;
    }
  }
  .expandRoomListing {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 35px;
    row-gap: 15px;
    img {
      width: 80px;
      height: 80px;
      @include bradius(50%, 50%, 50%, 50%);
      display: block;
    }
  }
  .joinRoomButton {
    max-width: 270px;
    width: 100%;
  }
}
.wrapperGoldModal {
  padding: 30px 15px 50px;
  max-width: 580px;
}
.wrapperNoGoldPiece {
  max-width: 580px;
}
.goldPieceWrapper {
  text-align: center;
  img {
    width: 75px;
    height: 75px;
  }
  p {
    line-height: 19px;
    margin: 22px 35px;
  }
}
.wrapperNoPiece {
  max-width: 500px;
}
.noGoldPiece {
  padding: 5px 70px 20px;
  text-align: center;
  img {
    width: 40px;
  }
  .description {
    font-size: 23px;
    color: $primary-text-color;
    font-weight: 600;
    line-height: 33px;
    margin: 15px auto 25px;
  }
}
.converNearByBg {
  background-color: #f7f7f7;
  padding-block: 20px;
}
@import './src/assets/css/buttons';
.singUpBtn {
  @extend %signUpBtn;
  &:hover {
    color: $text-white;
  }
  &:focus {
    box-shadow: none;
  }
  .moreDetails {
    font-size: 12px;
  }
}

@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.itemWrapper {
  max-width: 165px;
  height: auto;
  box-shadow: 0 3px 6px #0000001C;
  @include bradius(8px, 8px, 8px, 8px);
  padding: 5px;
  .itemInner {
    .cardHeader {
      background-color: $bg-yellow;
      height: 50px;
      width: 100%;
      padding: 5px 12px;
      @include bradius(4px, 4px, 4px, 4px);
      display: flex;
      align-items: center;
      justify-content: center;
      h4 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: center;
      }
    }
    .userImageWrapper {
      width: 80px;
      height: 80px;
      @include bradius(50%, 50%, 50%, 50%);
      border: 4px solid $secondary-text-color;
      position: relative;
      margin: 15px auto;
      .userImage {
        width: 100%;
        height: 100%;
        display: inline-block;
        @include bradius(50%, 50%, 50%, 50%);
        object-fit: cover;
      }
      .superHostImg {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .divider {
      width: 75%;
      height: 2px;
      margin: 15px auto;
      background-color: $bg-grey;
    }
    .participantsWrapper {
      min-height: 10vw;
      max-height: 20vw;
      height: 100%;
      overflow-y: auto;
      .participantsInner {
        text-align: center;
        margin-bottom: 5px;
        img {
          width: 70px;
          height: 70px;
          @include bradius(50%, 50%, 50%, 50%);
        }
      }
    }
    .joinButton {
      width: 50px;
      height: 50px;
      margin: auto;
      display: block;
    }
    .expandButton {
      @include bradius(4px, 4px, 4px,4px);
      background-color: $bg-grey;
      color: $secondary-text-color;
      font-size: 13px;
      margin-top: 15px;
      box-shadow: 0 3px 6px $bg-grey;
    }
    .hostName {
      width: 85%;
      white-space: nowrap;
      margin-inline: auto;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.listingWrapper {
  margin: 0 0 0 -13px;
  padding: 0 0 30px;
  .listingTitle {
    pointer-events: none;
    background-color: $text-white;
    &:hover {
      background-color: $text-white;
      pointer-events: none;
    }
  }
  .listingTitle:first-child {
    padding-bottom: 16px;
  }
  .listingTitle:not(:first-child) {
    padding: 16px 0 13px 13px;
  }
  li {
    padding-left: 13px;
    //&:hover {
    //  background-color: $secondary-text-color;
    //  @include bradius(5px,5px,5px,5px);
    //  a {
    //    color: $text-white;
    //  }
    //}
    a {
      padding: 10px 0;
      display: block;
      width: 100%;
      height: 100%;
      color: $primary-text-color;
      line-height: 25px;
    }
  }
  li.active {
    background-color: $secondary-text-color;
    @include bradius(5px,5px,5px,5px);
    a {
      color: $text-white;
    }
  }
}

.logOut {
  padding-top: 26px;
  .btnStyle {
    background-color: transparent;
    box-shadow: none;
    color: $secondary-text-color;
    text-align: left;
    padding-inline: 0;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
  }
  img {
    display: inline-block;
    width: 17px;
    margin-left: 15px;
  }
}

@import 'src/assets/css/variables';

.wrapper {
  position: relative;
  min-height: 100vh;
  .bgImage {
    position: absolute;
    right: 0;
    width: 700px;
    height: 500px;
    bottom: 50px;
  }
  .innerSection {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    max-width: 600px;
    width: 100%;
    margin: auto;
    text-align: center;
    .socialLogo {
      max-width: 300px;
      object-fit: contain;
      margin-bottom: 30px;
    }
    .description {
      margin-bottom: 50px;
      font-size: 30px;
      &:nth-of-type(2) {
        margin-bottom: 15px;
      }
    }
    .playIcon {
      max-width: 60px;
      margin-bottom: 50px;
    }
    .copyRightText {
      font-size: 18px;
    }
  }
  .footerSection {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $secondary-text-color;
    padding: 15px;
    display: flex;
    justify-content: center;
    img {
      width: 30px;
    }
  }
}

@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 50px;
  h3 {
    margin: 0;
    font-size: $font-thirty;
    font-weight: 600;
    line-height: 19px;
    color: $primary-text-color;
  }
  .profileImageInner {
    flex: 0 0 170px;
    width: 100%;
    height: 170px;
    border: 5px solid #00AEEF;
    @include bradius(50%, 50%, 50%, 50%);
    padding: 8px;
    .profileImage {
      @include bradius(50%,50%,50%,50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    span {
      position: absolute;
      bottom: 5px;
      right: 0;
      background-color: $secondary-text-color;
      width: 37px;
      height: 37px;
      @include bradius(50%,50%,50%,50%);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 18px;
      }
      .fileUpload {
        width: 100%;
        cursor: pointer;
        position: absolute;
        height: 37px;
        bottom: 0;
        right: 0;
        top: 3px;
        left: 0;
        opacity: 0;
      }
    }
  }
}

.inputStyle {
  @include bradius(7px, 7px,7px,7px);
  background-color: $bg-grey !important;
  color: $primary-text-color !important;
  &::placeholder {
    color: $primary-text-color !important;
  }
}

.iconStyle {
  cursor: pointer;
  width: 18px;
  height: 18px;
  right: 25px;
}
.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 15px;
  padding-top: 48px;
  padding-bottom: 40px;
  .cancelBtn {
    width: auto;
    background-color: transparent;
    box-shadow: none;
    color: $secondary-text-color;
    &:hover, &:active, &:focus {
      color: $secondary-text-color;
    }
  }
  .saveBtn {
    max-width: 220px;
  }
}

.deleteAccountWrapper {
  margin-top: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 0.5px solid #B7B7B7;
  border-bottom: 0.5px solid #B7B7B7;
}


@import 'assets/css/variables';
@import 'assets/css/base';
@import 'assets/css/mixins';
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*:focus {
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
body,
html {
  min-height: 100%;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('../src/assets/fonts/Poppins-Regular.ttf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('../src/assets/fonts/Poppins-SemiBold.ttf');
  font-style: normal;
  font-display: swap;
}
a:hover,
a:active,
a:focus {
  color: #00aeef;
}

.slick-list {
  width: 100%;
  max-width: 1140px;
  margin: auto !important;
}
.slick-slider .slick-slide {
  padding: 15px 10px;
}
.slick-slide img {
  display: initial !important;
}

.slick-slider .prev-arrow {
  left: -10px !important;
}

.slick-slider .next-arrow {
  left: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
/* width */
.customScrollBar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.customScrollBar::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.customScrollBar::-webkit-scrollbar-thumb {
  background: #E6E6E6;
  border-radius: 30px;
}

/* Handle on hover */
.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #E6E6E6;
}
/*RESET CSS*/
.modal-open {
  padding-right: 17px;
}
.poppins-bold {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.poppins-regular {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
}
.fontEleven {
  font-size: $font-eleven;
}
.fontThirteen {
  font-size: $font-thirteen;
}
.fontFourteen {
  font-size: $font-fourteen;
}
.fontFifteen {
  font-size: $font-fifteen;
}
.fontSixteen {
  font-size: $primary-font-size;
}
.fontTwenty {
  font-size: $secondary-heading-size;
}
.text-black {
  color: $primary-text-color;
}
.text-blue {
  color: $secondary-text-color;
}
.text-warning {
  color: $warning-text-color;
}
.text-danger {
  color: $text-danger;
}
.input-color {
  color: $text-field-light-color;
}
.bg-blue {
  background-color: $primary-btn-bg-color;
}
.bg-grey {
  background-color: $bg-grey !important;
}
.text-grey {
  color: $bg-grey !important;
}
.column-gap-5 {
  column-gap: $column-gap-5;
}
.column-gap-10 {
  column-gap: $column-gap-10;
}
.column-gap-15 {
  column-gap: $column-gap-15;
}
.pt-20 {
  padding-top: 20px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-20 {
  padding-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
/*CUSTOM CSS*/
.column-gap-30 {
  column-gap: 30px;
}
.column-gap-20 {
  column-gap: 20px;
}
.horizontal-line {
  width: 100%;
  height: 2px;
  background-color: $bg-grey;
  margin: 20px 0;
}
.borderStyle {
  @include bradius(10px, 10px, 10px, 10px);
  box-shadow: 0 1px 3px #0000001c;
  height: auto;
  padding: 65px;
}
//selectBoxWrapper
.selectBoxWrapper.css-2b097c-container,
.selectBoxWrapper .css-yk16xz-control,
.selectBoxWrapper .css-1pahdxg-control {
  height: 60px;
  background-color: $bg-grey;
  border-color: transparent !important;
  @include bradius(7px, 7px, 7px, 7px);
  font-family: 'Poppins', sans-serif;
  box-shadow: none;
}
.selectBoxWrapper .css-yk16xz-control:hover,
.selectBoxWrapper .css-yk16xz-control:active,
.selectBoxWrapper .css-yk16xz-control:focus {
  border-color: transparent !important;
  outline: none;
  box-shadow: none;
}
.selectBoxWrapper .css-1uccc91-singleValue {
  font-size: 1rem;
  color: $text-field-light-color;
}
.selectBoxWrapper .css-1okebmr-indicatorSeparator {
  display: none;
}
.selectBoxWrapper .css-tj5bde-Svg {
  color: $secondary-text-color !important;
}
.selectBoxWrapper .css2613qy-menu {
  text-align: left !important;
}
.css-1wa3eu0-placeholder {
  font-weight: 500;
  color: $text-field-light-color !important;
}
.scheduleCmWrapper {
  .react-datetime-picker {
    height: 60px;
    background-color: $bg-grey;
    width: 100%;
    border-radius: 5px;
    input {
      height: 60px;
      &:active,
      &:focus {
        outline: none !important;
      }
    }
    .react-datetime-picker__inputGroup__input {
      color: $text-field-light-color;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
    }
    .react-datetime-picker__inputGroup__divider,
    span {
      color: $text-field-light-color;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
    }
  }
  .react-datetime-picker__inputGroup {
    padding: 0 2px 0 13px;
  }
  .react-datetime-picker__wrapper {
    border: none;
  }
  .react-datetime-picker__inputGroup__amPm:active,
  .react-datetime-picker__inputGroup__amPm:focus {
    outline: none;
  }
  .react-datetime-picker__button svg {
    stroke: $secondary-text-color;
  }
  .react-datetime-picker__button:hover svg,
  .react-datetime-picker__button:active svg,
  .react-datetime-picker__button:focus svg {
    stroke: $secondary-text-color !important;
  }
  .react-datetime-picker__button {
    &:last-child {
      padding-right: 10px;
    }
  }
  .react-datetime-picker__inputGroup__input:invalid {
    background: $bg-grey !important;
  }
}
.form-group {
  position: relative;
}
.spinner-loader {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.buttonSpinnerPosition {
  position: relative;
  top: 2px;
}
.jistiWrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

#jitsiMeeting-0 {
  width: 100% !important;
  border: 0 !important;
  height: 100%;
}

.jitsiMeetingWrapper {
  padding: 60px 80px;
  width: 100% !important;
  background-color: #363636 !important;
  border: 0 !important;
  height: 100vh !important;
}

.broadcastBackIcon {
  font-size: 25px;
  font-weight: bold;
  color: #FFFFFF;
  opacity: 1;
}

.broadcastRoomName {
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0;
  opacity: 1;
  margin-left: 25px;
}

.broadCastInviteFriend {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
}

.broadcastPlusIcon {
  margin-left: 5px;
  font-weight: bold;
  font-size: 12px;
  color: #FFFFFF;
  opacity: 1;
}

.line {
  color: #ffffff;
  margin: 0 20px;
}

.hostWrapper {
  background-color: #FBB040;
  border-radius: 4px;
  opacity: 1;
  padding: 4px 20px;
}

.hostName {
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
}

.participantIcon {
  width: 19px;
  color: #FFFFFF;
  opacity: 1;
  margin-right: 7px;
  margin-left: 20px;
}

.participants {
  font-size: 19px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.inviteFriendModal {
  max-height: 620px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.inviteFriendPopupWrapper {
  padding: 0 65px !important;
  box-shadow: none;
}

.new-toolbox.visible {
  display: none !important;
}
//lightbox

.lightBoxWrapper {
  ._20cg_ {
    background-color: transparent;
  }
  ._2pWTS {
    left: 39%;
    transform: translateX(-50%);
    button {
      position: relative;
      &:hover {
        background-color: #f7f7f7;
      }
      &:active,
      &:focus {
        box-shadow: 0px 0px 0px 3px #00aeef;
      }
      &:before {
        content: '';
        position: absolute;
        background-image: url('assets/svg/left arrow_blue.svg');
        background-position: center center;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
      }
      svg {
        display: none;
      }
    }
  }
  ._18xMp {
    right: 37%;
    transform: translateX(-50%);
    button {
      position: relative;
      &:hover {
        background-color: #f7f7f7;
      }
      &:active,
      &:focus {
        box-shadow: 0px 0px 0px 3px #00aeef;
      }
      &:before {
        content: '';
        position: absolute;
        background-image: url('assets/svg/right arrow_blue.svg');
        background-position: center center;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
      }
      svg {
        display: none;
      }
    }
  }
}
.react-datetime-picker__inputGroup__input--hasLeadingZero {
  position: relative;
  top: 0.5px;
}
.disabledBtn {
  background-color: $text-field-light-color !important;
  border-color: $text-field-light-color;
  box-shadow: none !important;
}

.imageLoader .spinner-border-sm {
  width: 20px !important;
  height: 20px !important;
  left: 9px;
  top: 8px;
}
.lightBoxWrapper > div div:nth-of-type(1) {
  display: none;
}
.lightBoxWrapper > div svg {
  fill: $secondary-text-color;
}
.lightBoxWrapper > div img {
  max-width: 80% !important;
  max-height: 80% !important;
  @include bradius(5px, 5px, 5px, 5px);
}
.errorButton {
  border-color: $text-danger;
  background-color: $text-field-light-color !important;
}
.personalInfoWrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.personalInfoWrapper .col-md-6, .personalInfoWrapper .col-md-4  {
  padding-left: 5px;
  padding-right: 5px;
}
.deleteAccountSection {
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
}
/*BreakPoints*/
@media only screen and (max-width: 374px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.messageThreadWrapper {
  flex: 0 0 284px;
  max-width: 284px;
  width: 100%;
  @include bradius(8px, 8px, 8px, 8px);
  background-color: $text-white;
  padding: 10px;
  position: relative;
  border-left: 1px solid $bg-grey;

  .header {
    background-color: $light-blue;
    padding: 12px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 20px;
    margin-bottom: 20px;
    @include bradius(5px, 5px, 5px, 5px);
    img {
      width: 15px;
    }
  }
  .BodyContent {
    height: calc(100% - 160px);
    overflow-y: auto;
  }
  .message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    column-gap: 15px;
    padding: 5px 10px;
    @include bradius(5px, 5px, 5px, 5px);
    position: relative;
    .actions {
      position: absolute;
      right: 15px;
      display: none;
      img {
        width: 20px;
        margin-left: 15px;
        display: inline-block;
      }
    }
    &:hover {
      background-color: $bg-grey;
      @include bradius(5px, 5px, 5px, 5px);
      .actions {
        display: block;
      }
    }
    .userImage {
      width: 54px;
      height: 54px;
      @include bradius(50%, 50%, 50%, 50%);
      object-fit: cover;
    }
    .userDetails {
      flex: 0 0 calc(100% - 69px);
      max-width: calc(100% - 69px);
      width: 100%;
      .detailsInner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 30px;
        //padding-right: 70px;
        h5 {
          margin-right: 10px;
          font-size: 16px;
          color: $primary-text-color;
          font-weight: 600;
        }
        .hostBadge {
          background-color: $warning-text-color;
          font-size: 11px;
          font-weight: 500;
          text-align: center;
          padding: 5px 10px;
          @include bradius(3px, 3px, 3px, 3px);
          margin-left: 10px;
        }
      }
      .uploadedImageDetails {
        font-size: 11px;
        color: $text-field-light-color;
        font-weight: normal;
      }
      .uploadedImage {
        max-width: 300px;
        @include bradius(5px, 5px, 5px, 5px);
      }
    }
  }
  .borderBottom {
    border-bottom: 2px solid $bg-grey;
    padding-bottom: 10px;
  }
}
.typingUser {
  display: inline-block;
  padding-bottom: 8px;
}
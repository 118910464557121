@import "src/assets/css/variables";
/* Rounded sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin-bottom: 0;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
}
input {
  &:checked {
    + {
      .slider {
        background-color: $bg-success;
        &:before {
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
      }
    }
  }
}
.slider.round {
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}

@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.profileBox {
  @include bradius(8px, 8px, 8px, 8px);
  padding: 30px 40px;
  margin-top: 55px;
  box-shadow: 0 2px 4px #0000001C;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .profileBoxInner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 30px;
  .profileImageWrapper {
    width: 125px;
    height: 125px;
    position: relative;
      .profileImage {
        width: 100%;
        border: 4px solid $secondary-text-color;
        @include bradius(50%, 50%, 50%, 50%);
      }
      .superHostKey {
        position: absolute;
        bottom: 10px;
        right: 5px;
        width: 30px;
      }
    }
    > div {
      h3 {
        font-size: $font-thirty;
        color: $primary-text-color;
        margin: 0;
        font-weight: 700;
        word-break: break-all;
      }
      p {
        color: $warning-text-color;
        font-weight: 600;
        font-size: $font-fourteen;
        margin-bottom: 0;
      }
    }
  }
  .followBtn {
    max-width: 220px;
    width: 220px;
    background-color: #00aeef;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 3px 6px #00aeef33;
    height: 45px;
    display: inline-flex;
    border: 0;
    align-items: center;
    justify-content: center;
    @include bradius(23px, 23px, 23px, 23px);
}
  .followToolTip {
    position: absolute;
    max-width: 250px;
    width: 250px;
    background-color: $warning-text-color;
    padding: 20px;
    left: 30px;
    bottom: -110px;
    border-radius: 20px;
    &:after {
      content: '';
      position: absolute;
      top: -15px;
      left: 50px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid $warning-text-color;
    }
     > p {
       font-size: 14px;
       font-weight: 500;
       color: $text-white;
    }
  }
}
.cardWrapper {
  padding: 50px 30px 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 20px;

  .cardInner {
    width: 100%;
    background-color: $bg-grey;
    padding: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include bradius(10px, 10px, 10px, 10px);

    .cardDetails {
      flex: 0 0 45%;
      max-width: 45%;
      h5 {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }

      p {
        margin: 0;
        color: $primary-text-color;
      }
    }
    h3 {
      font-size: 35px;
      font-weight: 600;
      margin: 0;
      color: $primary-text-color;
    }
  }
}
.openCardWrapper {
  padding: 30px 20px;
  h4 {
    margin-bottom: 20px;
    font-size: 20px;
    color: $primary-text-color;
    line-height: 28px;
    font-weight: 600;
  }
  .openRoomCard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 20px;
    .openCardInner {
      width: 100%;
      background-color: $text-white;
      padding: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $secondary-text-color;
      @include bradius(10px, 10px, 10px, 10px);
      h5 {
        font-size: 19px;
      }
      .topicBtn {
        background-color: $warning-text-color;
        color: $text-white;
        @include bradius(4px, 4px, 4px, 4px);
        padding: 4px 1rem;
        font-size: 18px;
        line-height: 27px;
        margin-left: 1rem;
      }
      .distanceWrapper {
        > div {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 10px;
          img {
            width: 18px;
          }
        }
      }
      .joinBtnWrapper {
        img {
          max-width: 45px;
          width: 100%;
        }
      }
    }
  }
}
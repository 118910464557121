@import 'src/assets/css/variables';
@import 'src/assets/css/mixins';

.timeFormatWrapper {
  padding: 0 50px 50px;
  .timeFormatInner {
   flex: 0 0 65%;
  }
  .btnWidth {
    max-width: 220px;
  }
}

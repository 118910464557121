@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.rechargeCardInner {
  padding-block: 50px;
  position: relative;
  border: 3px solid $bg-grey;
  @include bradius(10px, 10px, 10px, 10px);
  box-shadow: 0 1px 3px #0000001C;
  max-width: 19.688rem;
  flex: 0 0 50%;
  width: 100%;
  .saveText {
    background-color: $warning-text-color;
    padding: 3px 10px;
    color: $text-white;
    position: absolute;
    right: 7px;
    top: 9px;
    @include bradius(4px, 4px, 4px, 4px);
    font-size: 14px;
  }
  text-align: center;
  h6 {
    font-size: 25px;
    color: $primary-text-color;
    font-weight: 600;
  }
  > img {
    width: 110px;
    height: 110px;
    margin-block: 20px;
  }
  p {
    font-size: 34px;
    color: $secondary-text-color;
    font-weight: 600;
    margin-bottom: 0;
  }
}
.blueBorder {
  border: 3px solid $secondary-text-color;
}
@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.ChatFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  .messageBox {
    flex: 0 0 calc(100% - 45px);
    max-width: calc(100% - 45px);
    width: 100%;
  }

  .typingArea {
    width: 100%;
    padding: 15px 40px 15px 25px;
    @include bradius(29px, 29px, 29px, 29px);
    background-color: $bg-grey;
    border: 1px solid $bg-grey;
    color: $text-field-light-color;
    font-size: 15px;
    font-weight: normal;
    &::placeholder {
      color: $text-field-light-color;
    }
    &:hover, &:active, &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  img {
    width: 30px;
  }
  .attachedFilesWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    .attachedFiles {
      width: 25px;
      height: 100%;
      input {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 100%;
        opacity: 0;
      }
      img {
        width: 16px;
      }
    }
  }
}
@import "src/assets/css/variables";

.modalWrapper {
  max-width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  z-index: 3;
  .modalContent {
    width: 100%;
    border-radius: 0.5rem;
    z-index: 3;
    .modalHeader {
      padding: 15px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .modalTitle {
        font-size: 23px;
        line-height: 33px;
        color: $primary-text-color;
      }
    }
  }
}
.modalBgLayer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}


@media only screen and (min-width: 992px){
  .modalWrapper {
    .modalContent {
      .modalBody {
        max-height: 600px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}

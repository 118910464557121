@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.messageThreadContainer {
  padding-block: 10px;
  border-left: 1px solid $bg-grey;
}
.messageThreadWrapper {
  flex: 0 0 284px;
  max-width: 284px;
  width: 100%;
  padding-block: 10px;
  @include bradius(8px, 8px, 8px, 8px);
  background-color: $text-white;
  padding: 15px 10px 15px 15px;
  position: relative;
  height: 100%;
  overflow-y: auto;
  .userImageWrapper {
    width: 95px;
    height: 95px;
    margin: 20px auto;
    img:first-child {
      width: 100%;
      height: 100%;
      @include bradius(50%, 50%, 50%, 50%);
      border: 4px solid $secondary-text-color;
      object-fit: cover;
    }
    .superHostImage {
      position: absolute;
      bottom: 5px;
      right: 0;
      width: 30px;
    }
  }
  .chatParticipants {
    margin-top: 55px;
    .participantsWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 10px;
      img {
        @include bradius(50%, 50%, 50%, 50%);
        width: 40px;
        height: 40px;
        flex: 0 0 calc(50px - 10px);
      }
    }
  }
  .photosAndMedia {
    margin-top: 45px;
    .photosMediaWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 10px;
      width: 100%;
      img {
        flex: 0 0 33.33%;
        max-width: 76px;
        height: 70px;
        object-fit: cover;
      }
    }
  }
}
.wrapperClass {
  padding: 35px 50px 50px;
  width: 78%;
  .viewAllWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 35px;
    flex-wrap: wrap;
    row-gap: 15px;
    .viewAllInner {
      flex: 0 0 calc(115px - 35px);
      max-width: calc(115px - 35px);
      width: 100%;
      img {
        width: 80px;
        height: 80px;
        @include bradius(50%, 50%, 50%, 50%);
      }
    }
  }
  .closeBtn {
    max-width: 270px;
  }
}
.viewMediaWrapper {
  width: 100%;
  max-width: 900px;
  padding: 12px 50px 50px;
  .customHeader {
    margin-bottom: 40px;
   h3 {
     font-size: 23px;
     font-weight: 600;
     text-align: center;
     margin: 0;
   }
    .tagLine {
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      margin: 10px 0 0;
    }
  }
  .allMedia {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
    .allMediaInner {
      flex: 0 0 calc(100% / 6 - 10px);
      .mediaTileWrapper {
        width: 125px;
        height: 125px;
      }
    }
    img {
      width: 100%;
      height: 125px;
      object-fit: cover;
    }
  }
  .closeBtn {
    max-width: 270px;
  }
}